@import './variable';

.first-section {
  background: $primaryColor;
  position: relative;
  background: url(/frontend/images/front-1920.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  header {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9;
    background: $secondaryColor;

    .header-menu__wrapper__list a:hover {
      color: $primaryColor;
    }
  }

  .row {
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container-content {
    position: relative;
    min-height: 700px;
    display: flex;
    align-items: center;

    @include respond-below(md) {
      align-items: flex-start;
      padding-top: 100px !important;
      min-height: 74vw !important;
    }

    @include respond-below(sm) {
      min-height: 550px !important;
    }

    .input-group {
      padding: 10px;
      background-color: #fff;
      border-radius: 10px;

      .input-group-text {
        padding: 0 !important;
        margin-left: 0 !important;

        .btn-custom {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      input {
        border: none !important;
        font-family: 'Poppins', sans-serif !important;
      }
    }

    h1 {
      color: #fff;
      margin-bottom: 40px;
      text-shadow: 1px 1px 2px black;

      @include respond-below(sm) {
        font-size: 24px !important;
        text-align: center;
      }
    }
  }
}

.home-first-boxes {
  --gap: 2rem;
  background-color: $secondaryColor;
  padding-block: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap);

  @include respond-below(sm) {
    --gap: .5rem;
    flex-direction: column;
  }

  p {
    color: #fff;
    margin-bottom: 0;
    font-size: 1.5em;

    @include respond-below(sm) {
      border: none;
      font-size: 1em;
      text-align: center;
      border-bottom: 1px solid $primaryColor;
      padding-block-end: var(--gap);

      &:last-child {
        border: none;
        padding-block-end: 0;
      }
    }
  }
}

.logos-section {
  padding: 80px 0;

  @include respond-below(sm) {
    padding: 40px 0;
  }

  &__heading-content {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }
  }

  &__logos-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 60px;

    @include respond-below(md) {
      max-width: 800px;
      margin: 0 auto;
    }

    &__col {
      flex-basis: 16.6%;
      max-width: 16.6%;
      text-align: center;
      min-height: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dbcbd9;

      @include respond-below(md) {
        flex-basis: 33.3%;
        max-width: 33.3%;
        border-bottom: 1px solid #dbcbd9;

        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(9),
        &:nth-of-type(12) {
          border-right: none;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3) {
          border-bottom: none;
        }
      }

      @include respond-above(md) {
        border-bottom: 1px solid #dbcbd9;

        &:nth-of-type(6),
        &:nth-of-type(12) {
          border-right: none;
        }

        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11),
        &:nth-of-type(12) {
          border-bottom: none;
        }
      }

      @include respond-below(sm) {
        flex-basis: 50%;
        max-width: 50%;
        border-bottom: 1px solid #dbcbd9 !important;

        &:nth-of-type(odd) {
          border-right: 1px solid #dbcbd9 !important;
        }

        &:nth-of-type(even) {
          border-right: none;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          border-bottom: none !important;
        }
      }

      img:hover {
        mix-blend-mode: exclusion;
      }
    }
  }
}

.services-section {
  background-color: #f8ecf6;

  .container {
    position: relative;
    padding-top: 60px !important;
    padding-bottom: 70px !important;

    @include respond-below(sm) {
      padding-bottom: 40px !important;
      padding-top: 40px !important;
    }
  }

  &__heading-content {
    max-width: 700px;
    margin-bottom: 50px;

    @include respond-below(sm) {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 20px;
    }
  }

  &__woman-finger {
    background: url('/frontend/images/finger.png');
    width: 500px;
    height: 500px;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: absolute;
    right: -200px;
    bottom: 0;
    pointer-events: none;
    z-index: 2;

    @include respond-below(lg) {
      right: -100px;
      width: 35%;
      height: 500px;
    }

    @include respond-below(md) {
      display: none;
    }
  }

  &__boxes {
    max-width: 969px;

    @include respond-below(lg) {
      max-width: 82%;
    }

    @include respond-below(md) {
      max-width: 100%;
    }

    &__box-single {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      transition: all 0.2s ease;

      &:hover {
        box-shadow: 0 10px 20px rgba(122, 54, 118, 0.1);
      }

      @include respond-below(sm) {
        margin-bottom: 20px;
      }

      &__group {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;

        @include respond-below(sm) {
          margin-bottom: 20px;
        }

        img {
          flex-basis: 59px;
          max-width: 59px;
        }

        h4 {
          max-width: calc(100% - 59px);
          flex-basis: calc(100% - 59px);
          margin-bottom: 0;
        }
      }
    }
  }
}

.people-section {
  padding: 80px 0;

  @include respond-below(sm) {
    padding: 40px 0;
  }

  .row {
    align-items: center;
  }

  &__carousel-left .owl-item {
    opacity: 0;
    transition: all 0.3s ease;
    transform-origin: center left;

    @include respond-below(sm) {
      transform: scale(0.5);
    }

    >div {
      border-radius: 50%;
    }

    &:nth-of-type(3n + 1)>div {
      background-color: $secondaryColor;
    }

    &:nth-of-type(3n + 2)>div {
      background-color: #ce6044;
    }

    &:nth-of-type(3n + 3)>div {
      background-color: $primaryColor;
    }
  }

  &__carousel-left .owl-item.first,
  &__carousel-left .owl-item.middle,
  &__carousel-left .owl-item.last {
    opacity: 1;
  }

  &__carousel-left .owl-item.zero,
  &__carousel-left .owl-item.first {
    transform: scale(0.5);
  }

  &__carousel-left .owl-item.middle {
    transform: scale(0.75);

    @include respond-below(sm) {
      transform-origin: center;
      z-index: 10;
      position: relative;
    }
  }

  &__carousel-left .owl-item.last {
    @include respond-below(sm) {
      transform: scale(0.5);
      transform-origin: center right;
    }
  }

  &__people-arrows {
    display: flex;
    gap: 30px;

    svg {

      path,
      circle {
        transition: all 0.2s ease;
      }

      @include respond-below(sm) {
        height: 50px;
        width: 50px;
      }
    }

    @include respond-below(sm) {
      justify-content: center;
    }

    svg:hover circle {
      fill: #f79837;
    }

    svg:hover path {
      fill: #fff;
    }
  }
}

.multistep-section {
  padding: 90px 0 80px;
  background: url('/frontend/images/multistep-background.png');
  background-size: cover;
  background-position: center;

  @include respond-below(sm) {
    padding: 40px 0 40px;
  }

  .container {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 33%;
      height: 100%;
      pointer-events: none;
      background: url('/frontend/images/multistep-women.png');
      background-size: contain;
      background-position: bottom left;
      background-repeat: no-repeat;
      bottom: -80px;
      left: -220px;
      z-index: 2;

      @include respond-below(xlg) {
        left: auto;
        right: calc(100% - 168px);
        width: 27vw;
      }

      @include respond-below(lg) {
        width: 250px;
      }

      @include respond-below(sm) {
        width: 150px;
        left: -56px;
        bottom: -40px;
      }
    }
  }

  &__heading-content {
    text-align: center;

    &__question {
      display: none;
      min-height: 30px;
      margin-top: 20px;

      @include respond-below(md) {
        min-height: 48px;
      }

      &.active {
        display: block;
      }
    }
  }

  &__box-wrapper {
    background: #ffffff;
    box-shadow: 20px 20px 30px rgba(120, 54, 116, 0.2);
    border-radius: 30px;
    padding: 70px 100px 30px;
    margin-top: 40px;

    @include respond-below(md) {
      padding: 50px 35px 20px;
    }

    &__carousel {
      padding-bottom: 50px;

      .owl-dots {
        margin-top: 40px !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .owl-dot span {
          background-color: #f8ecf6 !important;
          margin: 5px;
        }

        .owl-dot.active span {
          background-color: $secondaryColor !important;
        }
      }

      .btn-multi {
        background-color: $primaryColor;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        padding: 8px 0;
        display: inline-block;
        width: 255px;
        text-align: center;
        position: relative;
        border-radius: 10px;
        margin-top: 50px;

        &:hover {
          background-color: $secondaryColor;
        }

        svg {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .multi-carousel-video-single .btn-multi {
        margin-top: 20px !important;
      }

      .radio-wrapper {
        display: flex;
        justify-content: space-between;

        @include respond-below(sm) {
          flex-direction: column;
        }

        input {
          display: none;
        }

        label {
          background-color: $lightBgColor;
          padding: 25px;
          color: $headingColor;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
          border-radius: 20px;
          cursor: pointer;
          transition: all 0.2s ease;
          min-height: 124px;
          max-width: calc(33.3% - 20px);
          flex-basis: calc(33.3% - 20px);
          display: flex;
          align-items: center;

          @include respond-below(sm) {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 30px;
            min-height: 160px;
          }
        }

        label:hover {
          opacity: 0.8;
        }

        &.radio-wrapper-2 label {
          max-width: calc(25% - 20px);
          flex-basis: calc(25% - 20px);

          @include respond-below(sm) {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 30px;
            min-height: 112.5px;
          }
        }

        input:checked+label {
          background-color: $secondaryColor;
          color: #fff;
          opacity: 1;
        }
      }

      .videos-wrapper {
        display: flex;
        justify-content: space-between;

        @include respond-below(sm) {
          flex-direction: column;
        }

        .video-single {
          max-width: calc(33.3% - 20px);
          flex-basis: calc(33.3% - 20px);
          border-radius: 20px;
          position: relative;
          overflow: hidden;
          height: 180px;

          @include respond-below(sm) {
            max-width: 100%;
            flex-basis: 100%;
            min-height: 180px;
            margin-bottom: 20px;
          }

          video {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }

          a {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            svg {

              path,
              rect {
                transition: all 0.2s ease;
              }
            }

            &:hover {
              svg rect {
                fill: $secondaryColor;
              }

              svg path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.reviews-section {
  padding: 100px 0 150px;
  background-color: $lightBgColor;

  @include respond-below(sm) {
    padding: 40px 0;
  }

  .container {
    max-width: 1440px !important;
    position: relative;
    overflow: hidden;
    padding-right: 0 !important;
  }

  &__row {
    display: flex;
    align-items: center;

    @include respond-below(sm) {
      flex-direction: column;
    }

    &__col-left {
      max-width: 450px;
      flex-basis: 450px;
      min-width: 450px;
      margin-left: auto;
      margin-right: 50px;

      @include respond-below(md) {
        min-width: 300px;
      }

      @include respond-below(sm) {
        flex-basis: auto;
        margin-bottom: 40px;
      }

      h2 {
        margin-bottom: 20px;
        // max-width: 393px;
      }

      p {
        max-width: 290px;
        color: $headingColor;
        font-family: 'Poppins', sans-serif;
      }

      hr {
        border-top: 1px solid #dbcbd9;
        margin: 20px 0 30px;
        max-width: 325px;
      }

      &__arrows {
        display: flex;
        gap: 20px;

        a:hover {
          opacity: 0.7;
        }
      }
    }

    &__col-right {
      position: relative;
      max-width: 800px;

      @include respond-below(sm) {
        max-width: 100% !important;
      }

      &::before {
        position: absolute;
        content: '';
        width: 500px;
        height: 500px;
        left: 0;
        top: 50%;
        pointer-events: none;
        transform: translateX(-20%) translateY(-50%);
        background: radial-gradient(50% 50% at 50% 50%,
            rgba(190, 172, 187, 0.5) 0%,
            rgba(190, 172, 187, 0) 100%);
      }

      &__carousel {
        &__single {
          background: #ffffff;
          border-radius: 20px;
          padding: 30px;

          @include respond-below(sm) {
            padding: 20px;
          }

          hr {
            border-top: 1px solid #d4dee5;
            margin: 20px 0 30px;

            @include respond-below(sm) {
              margin: 15px 0 20px;
            }
          }

          &__content {
            line-height: 2.2;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            &__left {
              display: flex;
              align-items: center;

              >img {
                width: 49px !important;
                height: 49px;
                object-fit: cover;
              }

              &__namestar {
                margin-left: 15px;

                h4 {
                  font-size: 20px;
                  font-weight: 400 !important;
                  margin-bottom: 5px !important;
                  font-family: 'Josefin Sans', sans-serif !important;
                }

                img {
                  width: 88px !important;
                }
              }
            }

            &__right {
              img {
                width: 50px !important;
              }
            }
          }
        }
      }
    }
  }
}

.phone-section {
  padding: 100px 0 100px;
  background-image: url('/frontend/images/phone-section-bg.png');
  background-size: cover;
  background-position: center;

  @include respond-below(md) {
    padding: 60px 0;
  }

  @include respond-below(sm) {
    padding: 40px 0 80px;
  }

  .container {
    position: relative;
  }

  &__heading-content {
    h2 {
      color: #fff;

      @include respond-below(sm) {
        max-width: 400px;
      }
    }

    p {
      color: #fff;
      max-width: 303px;
      margin: 20px 0 40px;

      @include respond-below(sm) {
        margin: 20px 0 20px;
      }
    }

    &__dual-btn {
      display: flex;
      gap: 20px;

      @include respond-below(sm) {
        flex-direction: column;
      }

      a:hover {
        opacity: 0.8;
      }

      img {
        @include respond-below(sm) {
          width: 150px;
        }
      }
    }
  }

  &__phone-div {
    position: absolute;
    right: -12%;
    width: 60%;
    bottom: -100px;
    pointer-events: none;

    @include respond-below(md) {
      width: 57%;
      right: -12%;
      bottom: -60px;
    }

    @include respond-below(sm) {
      width: 65%;
      right: -15%;
      bottom: -80px;
    }

    &::before {
      position: absolute;
      content: '';
      width: 60%;
      height: 0;
      padding-top: 60%;
      background-color: $primaryColor;
      border-radius: 50%;
      top: 40%;
      pointer-events: none;
      left: -5%;
    }

    img {
      position: relative;
    }
  }
}

.faq-section {
  padding: 100px 0;
  background-color: $lightBgColor;

  @include respond-below(sm) {
    padding: 40px 0;
  }

  .container {
    max-width: 960px !important;
  }

  &__heading-content {
    text-align: center;
  }

  &__more-faq {
    .btn-custom {
      width: 255px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-top: 30px;
    }
  }

  &__accordion {
    margin-top: 40px;

    .accordion-item {
      border: none !important;
      margin-bottom: 15px;
      border-radius: 8px !important;
      overflow: hidden;
      transition: all 0.5s ease;

      &.active {
        box-shadow: 0 40px 30px rgba(190, 172, 187, 0.3) !important;
        z-index: 5;
        position: relative;
      }
    }

    .accordion-body {
      border-top: 1px solid #d4dee5 !important;
      margin: 0 20px !important;
      padding: 20px 0 !important;

      @include respond-below(sm) {
        font-size: 14px !important;
      }
    }

    .accordion-button {
      box-shadow: none !important;
      background-color: #fff !important;
      color: $headingColor !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      gap: 20%;
      line-height: 1.6 !important;
      min-height: 75px;

      @include respond-below(sm) {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        font-size: 14px !important;
        gap: 10px;
      }
    }

    .accordion-button::after {
      background-image: url('/frontend/images/icons/faq-arrow.svg') !important;
    }
  }
}

@media (min-width: 1441px) {
  .reviews-section .container::before {
    position: absolute;
    content: '';
    width: 100px;
    height: 100%;
    background: linear-gradient(to left, #fef7fd 80%, transparent);
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }
}

@import 'kurses-section';