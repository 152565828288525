.first-section {
  background: #f79837;
  position: relative;
  background: url(/frontend/images/front-1920.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.first-section header {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
  background: #732f6f;
}
.first-section header .header-menu__wrapper__list a:hover {
  color: #f79837;
}
.first-section .row {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.first-section .container-content {
  position: relative;
  min-height: 700px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .first-section .container-content {
    align-items: flex-start;
    padding-top: 100px !important;
    min-height: 74vw !important;
  }
}
@media (max-width: 767px) {
  .first-section .container-content {
    min-height: 550px !important;
  }
}
.first-section .container-content .input-group {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}
.first-section .container-content .input-group .input-group-text {
  padding: 0 !important;
  margin-left: 0 !important;
}
.first-section .container-content .input-group .input-group-text .btn-custom {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.first-section .container-content .input-group input {
  border: none !important;
  font-family: "Poppins", sans-serif !important;
}
.first-section .container-content h1 {
  color: #fff;
  margin-bottom: 40px;
  text-shadow: 1px 1px 2px black;
}
@media (max-width: 767px) {
  .first-section .container-content h1 {
    font-size: 24px !important;
    text-align: center;
  }
}

.home-first-boxes {
  --gap: 2rem;
  background-color: #732f6f;
  padding-block: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap);
}
@media (max-width: 767px) {
  .home-first-boxes {
    --gap: .5rem;
    flex-direction: column;
  }
}
.home-first-boxes p {
  color: #fff;
  margin-bottom: 0;
  font-size: 1.5em;
}
@media (max-width: 767px) {
  .home-first-boxes p {
    border: none;
    font-size: 1em;
    text-align: center;
    border-bottom: 1px solid #f79837;
    padding-block-end: var(--gap);
  }
  .home-first-boxes p:last-child {
    border: none;
    padding-block-end: 0;
  }
}

.logos-section {
  padding: 80px 0;
}
@media (max-width: 767px) {
  .logos-section {
    padding: 40px 0;
  }
}
.logos-section__heading-content {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}
.logos-section__heading-content h2 {
  margin-bottom: 20px;
}
.logos-section__logos-grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 60px;
}
@media (max-width: 991px) {
  .logos-section__logos-grid {
    max-width: 800px;
    margin: 0 auto;
  }
}
.logos-section__logos-grid__col {
  flex-basis: 16.6%;
  max-width: 16.6%;
  text-align: center;
  min-height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dbcbd9;
}
@media (max-width: 991px) {
  .logos-section__logos-grid__col {
    flex-basis: 33.3%;
    max-width: 33.3%;
    border-bottom: 1px solid #dbcbd9;
  }
  .logos-section__logos-grid__col:nth-of-type(3), .logos-section__logos-grid__col:nth-of-type(6), .logos-section__logos-grid__col:nth-of-type(9), .logos-section__logos-grid__col:nth-of-type(12) {
    border-right: none;
  }
  .logos-section__logos-grid__col:nth-last-of-type(1), .logos-section__logos-grid__col:nth-last-of-type(2), .logos-section__logos-grid__col:nth-last-of-type(3) {
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .logos-section__logos-grid__col {
    border-bottom: 1px solid #dbcbd9;
  }
  .logos-section__logos-grid__col:nth-of-type(6), .logos-section__logos-grid__col:nth-of-type(12) {
    border-right: none;
  }
  .logos-section__logos-grid__col:nth-of-type(7), .logos-section__logos-grid__col:nth-of-type(8), .logos-section__logos-grid__col:nth-of-type(9), .logos-section__logos-grid__col:nth-of-type(10), .logos-section__logos-grid__col:nth-of-type(11), .logos-section__logos-grid__col:nth-of-type(12) {
    border-bottom: none;
  }
}
@media (max-width: 767px) {
  .logos-section__logos-grid__col {
    flex-basis: 50%;
    max-width: 50%;
    border-bottom: 1px solid #dbcbd9 !important;
  }
  .logos-section__logos-grid__col:nth-of-type(odd) {
    border-right: 1px solid #dbcbd9 !important;
  }
  .logos-section__logos-grid__col:nth-of-type(even) {
    border-right: none;
  }
  .logos-section__logos-grid__col:nth-last-of-type(1), .logos-section__logos-grid__col:nth-last-of-type(2) {
    border-bottom: none !important;
  }
}
.logos-section__logos-grid__col img:hover {
  mix-blend-mode: exclusion;
}

.services-section {
  background-color: #f8ecf6;
}
.services-section .container {
  position: relative;
  padding-top: 60px !important;
  padding-bottom: 70px !important;
}
@media (max-width: 767px) {
  .services-section .container {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }
}
.services-section__heading-content {
  max-width: 700px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .services-section__heading-content {
    margin-bottom: 30px;
  }
}
.services-section__heading-content h2 {
  margin-bottom: 20px;
}
.services-section__woman-finger {
  background: url("/frontend/images/finger.png");
  width: 500px;
  height: 500px;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;
  right: -200px;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
}
@media (max-width: 1199px) {
  .services-section__woman-finger {
    right: -100px;
    width: 35%;
    height: 500px;
  }
}
@media (max-width: 991px) {
  .services-section__woman-finger {
    display: none;
  }
}
.services-section__boxes {
  max-width: 969px;
}
@media (max-width: 1199px) {
  .services-section__boxes {
    max-width: 82%;
  }
}
@media (max-width: 991px) {
  .services-section__boxes {
    max-width: 100%;
  }
}
.services-section__boxes__box-single {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.2s ease;
}
.services-section__boxes__box-single:hover {
  box-shadow: 0 10px 20px rgba(122, 54, 118, 0.1);
}
@media (max-width: 767px) {
  .services-section__boxes__box-single {
    margin-bottom: 20px;
  }
}
.services-section__boxes__box-single__group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .services-section__boxes__box-single__group {
    margin-bottom: 20px;
  }
}
.services-section__boxes__box-single__group img {
  flex-basis: 59px;
  max-width: 59px;
}
.services-section__boxes__box-single__group h4 {
  max-width: calc(100% - 59px);
  flex-basis: calc(100% - 59px);
  margin-bottom: 0;
}

.people-section {
  padding: 80px 0;
}
@media (max-width: 767px) {
  .people-section {
    padding: 40px 0;
  }
}
.people-section .row {
  align-items: center;
}
.people-section__carousel-left .owl-item {
  opacity: 0;
  transition: all 0.3s ease;
  transform-origin: center left;
}
@media (max-width: 767px) {
  .people-section__carousel-left .owl-item {
    transform: scale(0.5);
  }
}
.people-section__carousel-left .owl-item > div {
  border-radius: 50%;
}
.people-section__carousel-left .owl-item:nth-of-type(3n + 1) > div {
  background-color: #732f6f;
}
.people-section__carousel-left .owl-item:nth-of-type(3n + 2) > div {
  background-color: #ce6044;
}
.people-section__carousel-left .owl-item:nth-of-type(3n + 3) > div {
  background-color: #f79837;
}
.people-section__carousel-left .owl-item.first, .people-section__carousel-left .owl-item.middle, .people-section__carousel-left .owl-item.last {
  opacity: 1;
}
.people-section__carousel-left .owl-item.zero, .people-section__carousel-left .owl-item.first {
  transform: scale(0.5);
}
.people-section__carousel-left .owl-item.middle {
  transform: scale(0.75);
}
@media (max-width: 767px) {
  .people-section__carousel-left .owl-item.middle {
    transform-origin: center;
    z-index: 10;
    position: relative;
  }
}
@media (max-width: 767px) {
  .people-section__carousel-left .owl-item.last {
    transform: scale(0.5);
    transform-origin: center right;
  }
}
.people-section__people-arrows {
  display: flex;
  gap: 30px;
}
.people-section__people-arrows svg path,
.people-section__people-arrows svg circle {
  transition: all 0.2s ease;
}
@media (max-width: 767px) {
  .people-section__people-arrows svg {
    height: 50px;
    width: 50px;
  }
}
@media (max-width: 767px) {
  .people-section__people-arrows {
    justify-content: center;
  }
}
.people-section__people-arrows svg:hover circle {
  fill: #f79837;
}
.people-section__people-arrows svg:hover path {
  fill: #fff;
}

.multistep-section {
  padding: 90px 0 80px;
  background: url("/frontend/images/multistep-background.png");
  background-size: cover;
  background-position: center;
}
@media (max-width: 767px) {
  .multistep-section {
    padding: 40px 0 40px;
  }
}
.multistep-section .container {
  position: relative;
}
.multistep-section .container::before {
  position: absolute;
  content: "";
  width: 33%;
  height: 100%;
  pointer-events: none;
  background: url("/frontend/images/multistep-women.png");
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat;
  bottom: -80px;
  left: -220px;
  z-index: 2;
}
@media (max-width: 1399px) {
  .multistep-section .container::before {
    left: auto;
    right: calc(100% - 168px);
    width: 27vw;
  }
}
@media (max-width: 1199px) {
  .multistep-section .container::before {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .multistep-section .container::before {
    width: 150px;
    left: -56px;
    bottom: -40px;
  }
}
.multistep-section__heading-content {
  text-align: center;
}
.multistep-section__heading-content__question {
  display: none;
  min-height: 30px;
  margin-top: 20px;
}
@media (max-width: 991px) {
  .multistep-section__heading-content__question {
    min-height: 48px;
  }
}
.multistep-section__heading-content__question.active {
  display: block;
}
.multistep-section__box-wrapper {
  background: #ffffff;
  box-shadow: 20px 20px 30px rgba(120, 54, 116, 0.2);
  border-radius: 30px;
  padding: 70px 100px 30px;
  margin-top: 40px;
}
@media (max-width: 991px) {
  .multistep-section__box-wrapper {
    padding: 50px 35px 20px;
  }
}
.multistep-section__box-wrapper__carousel {
  padding-bottom: 50px;
}
.multistep-section__box-wrapper__carousel .owl-dots {
  margin-top: 40px !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.multistep-section__box-wrapper__carousel .owl-dots .owl-dot span {
  background-color: #f8ecf6 !important;
  margin: 5px;
}
.multistep-section__box-wrapper__carousel .owl-dots .owl-dot.active span {
  background-color: #732f6f !important;
}
.multistep-section__box-wrapper__carousel .btn-multi {
  background-color: #f79837;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding: 8px 0;
  display: inline-block;
  width: 255px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
}
.multistep-section__box-wrapper__carousel .btn-multi:hover {
  background-color: #732f6f;
}
.multistep-section__box-wrapper__carousel .btn-multi svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.multistep-section__box-wrapper__carousel .multi-carousel-video-single .btn-multi {
  margin-top: 20px !important;
}
.multistep-section__box-wrapper__carousel .radio-wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .multistep-section__box-wrapper__carousel .radio-wrapper {
    flex-direction: column;
  }
}
.multistep-section__box-wrapper__carousel .radio-wrapper input {
  display: none;
}
.multistep-section__box-wrapper__carousel .radio-wrapper label {
  background-color: #fef7fd;
  padding: 25px;
  color: #3e1844;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 124px;
  max-width: calc(33.3% - 20px);
  flex-basis: calc(33.3% - 20px);
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .multistep-section__box-wrapper__carousel .radio-wrapper label {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 30px;
    min-height: 160px;
  }
}
.multistep-section__box-wrapper__carousel .radio-wrapper label:hover {
  opacity: 0.8;
}
.multistep-section__box-wrapper__carousel .radio-wrapper.radio-wrapper-2 label {
  max-width: calc(25% - 20px);
  flex-basis: calc(25% - 20px);
}
@media (max-width: 767px) {
  .multistep-section__box-wrapper__carousel .radio-wrapper.radio-wrapper-2 label {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 30px;
    min-height: 112.5px;
  }
}
.multistep-section__box-wrapper__carousel .radio-wrapper input:checked + label {
  background-color: #732f6f;
  color: #fff;
  opacity: 1;
}
.multistep-section__box-wrapper__carousel .videos-wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .multistep-section__box-wrapper__carousel .videos-wrapper {
    flex-direction: column;
  }
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single {
  max-width: calc(33.3% - 20px);
  flex-basis: calc(33.3% - 20px);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  height: 180px;
}
@media (max-width: 767px) {
  .multistep-section__box-wrapper__carousel .videos-wrapper .video-single {
    max-width: 100%;
    flex-basis: 100%;
    min-height: 180px;
    margin-bottom: 20px;
  }
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single a {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single a svg path,
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single a svg rect {
  transition: all 0.2s ease;
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single a:hover svg rect {
  fill: #732f6f;
}
.multistep-section__box-wrapper__carousel .videos-wrapper .video-single a:hover svg path {
  fill: #fff;
}

.reviews-section {
  padding: 100px 0 150px;
  background-color: #fef7fd;
}
@media (max-width: 767px) {
  .reviews-section {
    padding: 40px 0;
  }
}
.reviews-section .container {
  max-width: 1440px !important;
  position: relative;
  overflow: hidden;
  padding-right: 0 !important;
}
.reviews-section__row {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .reviews-section__row {
    flex-direction: column;
  }
}
.reviews-section__row__col-left {
  max-width: 450px;
  flex-basis: 450px;
  min-width: 450px;
  margin-left: auto;
  margin-right: 50px;
}
@media (max-width: 991px) {
  .reviews-section__row__col-left {
    min-width: 300px;
  }
}
@media (max-width: 767px) {
  .reviews-section__row__col-left {
    flex-basis: auto;
    margin-bottom: 40px;
  }
}
.reviews-section__row__col-left h2 {
  margin-bottom: 20px;
}
.reviews-section__row__col-left p {
  max-width: 290px;
  color: #3e1844;
  font-family: "Poppins", sans-serif;
}
.reviews-section__row__col-left hr {
  border-top: 1px solid #dbcbd9;
  margin: 20px 0 30px;
  max-width: 325px;
}
.reviews-section__row__col-left__arrows {
  display: flex;
  gap: 20px;
}
.reviews-section__row__col-left__arrows a:hover {
  opacity: 0.7;
}
.reviews-section__row__col-right {
  position: relative;
  max-width: 800px;
}
@media (max-width: 767px) {
  .reviews-section__row__col-right {
    max-width: 100% !important;
  }
}
.reviews-section__row__col-right::before {
  position: absolute;
  content: "";
  width: 500px;
  height: 500px;
  left: 0;
  top: 50%;
  pointer-events: none;
  transform: translateX(-20%) translateY(-50%);
  background: radial-gradient(50% 50% at 50% 50%, rgba(190, 172, 187, 0.5) 0%, rgba(190, 172, 187, 0) 100%);
}
.reviews-section__row__col-right__carousel__single {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}
@media (max-width: 767px) {
  .reviews-section__row__col-right__carousel__single {
    padding: 20px;
  }
}
.reviews-section__row__col-right__carousel__single hr {
  border-top: 1px solid #d4dee5;
  margin: 20px 0 30px;
}
@media (max-width: 767px) {
  .reviews-section__row__col-right__carousel__single hr {
    margin: 15px 0 20px;
  }
}
.reviews-section__row__col-right__carousel__single__content {
  line-height: 2.2;
}
.reviews-section__row__col-right__carousel__single__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.reviews-section__row__col-right__carousel__single__header__left {
  display: flex;
  align-items: center;
}
.reviews-section__row__col-right__carousel__single__header__left > img {
  width: 49px !important;
  height: 49px;
  object-fit: cover;
}
.reviews-section__row__col-right__carousel__single__header__left__namestar {
  margin-left: 15px;
}
.reviews-section__row__col-right__carousel__single__header__left__namestar h4 {
  font-size: 20px;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  font-family: "Josefin Sans", sans-serif !important;
}
.reviews-section__row__col-right__carousel__single__header__left__namestar img {
  width: 88px !important;
}
.reviews-section__row__col-right__carousel__single__header__right img {
  width: 50px !important;
}

.phone-section {
  padding: 100px 0 100px;
  background-image: url("/frontend/images/phone-section-bg.png");
  background-size: cover;
  background-position: center;
}
@media (max-width: 991px) {
  .phone-section {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  .phone-section {
    padding: 40px 0 80px;
  }
}
.phone-section .container {
  position: relative;
}
.phone-section__heading-content h2 {
  color: #fff;
}
@media (max-width: 767px) {
  .phone-section__heading-content h2 {
    max-width: 400px;
  }
}
.phone-section__heading-content p {
  color: #fff;
  max-width: 303px;
  margin: 20px 0 40px;
}
@media (max-width: 767px) {
  .phone-section__heading-content p {
    margin: 20px 0 20px;
  }
}
.phone-section__heading-content__dual-btn {
  display: flex;
  gap: 20px;
}
@media (max-width: 767px) {
  .phone-section__heading-content__dual-btn {
    flex-direction: column;
  }
}
.phone-section__heading-content__dual-btn a:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  .phone-section__heading-content__dual-btn img {
    width: 150px;
  }
}
.phone-section__phone-div {
  position: absolute;
  right: -12%;
  width: 60%;
  bottom: -100px;
  pointer-events: none;
}
@media (max-width: 991px) {
  .phone-section__phone-div {
    width: 57%;
    right: -12%;
    bottom: -60px;
  }
}
@media (max-width: 767px) {
  .phone-section__phone-div {
    width: 65%;
    right: -15%;
    bottom: -80px;
  }
}
.phone-section__phone-div::before {
  position: absolute;
  content: "";
  width: 60%;
  height: 0;
  padding-top: 60%;
  background-color: #f79837;
  border-radius: 50%;
  top: 40%;
  pointer-events: none;
  left: -5%;
}
.phone-section__phone-div img {
  position: relative;
}

.faq-section {
  padding: 100px 0;
  background-color: #fef7fd;
}
@media (max-width: 767px) {
  .faq-section {
    padding: 40px 0;
  }
}
.faq-section .container {
  max-width: 960px !important;
}
.faq-section__heading-content {
  text-align: center;
}
.faq-section__more-faq .btn-custom {
  width: 255px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 30px;
}
.faq-section__accordion {
  margin-top: 40px;
}
.faq-section__accordion .accordion-item {
  border: none !important;
  margin-bottom: 15px;
  border-radius: 8px !important;
  overflow: hidden;
  transition: all 0.5s ease;
}
.faq-section__accordion .accordion-item.active {
  box-shadow: 0 40px 30px rgba(190, 172, 187, 0.3) !important;
  z-index: 5;
  position: relative;
}
.faq-section__accordion .accordion-body {
  border-top: 1px solid #d4dee5 !important;
  margin: 0 20px !important;
  padding: 20px 0 !important;
}
@media (max-width: 767px) {
  .faq-section__accordion .accordion-body {
    font-size: 14px !important;
  }
}
.faq-section__accordion .accordion-button {
  box-shadow: none !important;
  background-color: #fff !important;
  color: #3e1844 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  gap: 20%;
  line-height: 1.6 !important;
  min-height: 75px;
}
@media (max-width: 767px) {
  .faq-section__accordion .accordion-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 14px !important;
    gap: 10px;
  }
}
.faq-section__accordion .accordion-button::after {
  background-image: url("/frontend/images/icons/faq-arrow.svg") !important;
}

@media (min-width: 1441px) {
  .reviews-section .container::before {
    position: absolute;
    content: "";
    width: 100px;
    height: 100%;
    background: linear-gradient(to left, #fef7fd 80%, transparent);
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }
}
.kurses-section {
  padding: 80px 0;
}
@media (max-width: 767px) {
  .kurses-section {
    padding: 40px 0;
  }
}
.kurses-section__featured {
  background-color: #fef7fd;
  margin-bottom: 50px;
  border-radius: 20px;
  overflow: hidden;
}
.kurses-section__featured__left {
  height: 100%;
  position: relative;
  background-color: #732f6f;
}
.kurses-section__featured__left__badge {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 10px;
  background-color: #f79837;
  color: #fff;
  padding: 12px 40px;
  font-family: "Poppins";
  font-weight: 600;
}
.kurses-section__featured__left img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.kurses-section__featured__right {
  padding: 50px 40px 50px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .kurses-section__featured__right {
    padding: 30px 30px 30px 10px;
  }
}
@media (max-width: 767px) {
  .kurses-section__featured__right {
    padding: 30px;
  }
}
.kurses-section__featured__right__category {
  font-size: 14px;
  color: #f79837;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.kurses-section__featured__right__title {
  color: #3e1844;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.kurses-section__featured__right__price {
  font-size: 30px;
  color: #3e1844;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
@media (max-width: 767px) {
  .kurses-section__featured__right__price {
    font-size: 1.5em;
  }
}
.kurses-section__featured__right > p {
  margin: 30px 0;
}
@media (max-width: 767px) {
  .kurses-section__featured__right > p {
    margin: 10px 0 20px;
  }
}
.kurses-section__featured__right .row {
  align-items: center;
}
.kurses-section__featured__right .btn-custom {
  text-align: center;
  border-radius: 10px;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 19px;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .kurses-section__featured__right .btn-custom {
    padding-inline: 0.5em;
    padding-block: 0.5em;
    font-size: 1em;
  }
}
.kurses-section__grid__box {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}
.kurses-section__grid__box__course-thumbnail-wrapper {
  position: relative;
  height: 0;
  padding-top: 57%;
}
.kurses-section__grid__box__course-thumbnail-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kurses-section__grid__box__course-thumbnail-wrapper .heart-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.kurses-section__grid__box__course-thumbnail-wrapper .heart-button svg path,
.kurses-section__grid__box__course-thumbnail-wrapper .heart-button svg circle {
  transition: all 0.2s ease;
}
.kurses-section__grid__box__course-thumbnail-wrapper .heart-button:hover svg path {
  fill: #f79837;
}
.kurses-section__grid__box__content {
  padding: 20px 15px;
  background-color: #fef7fd;
}
.kurses-section__grid__box__content h3 {
  margin-bottom: 0;
  font-size: 18px !important;
}
.kurses-section__grid__box__content__course-category {
  color: #ee9f4c;
  font-size: 14px;
  margin-bottom: 5px;
}
.kurses-section__grid__box__content .row {
  margin-left: -5px;
  margin-right: -5px;
  align-items: center;
}
.kurses-section__grid__box__content .row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.kurses-section__grid__box__content__course-price {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  display: block;
  margin-bottom: 3px;
}
.kurses-section__grid__box__content__course-buy {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  padding-left: 25px;
  padding-right: 25px;
}
.kurses-section__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.kurses-section__pagination a {
  font-family: "Poppins", sans-serif;
  color: #3e1844;
}
.kurses-section__pagination a:hover {
  color: #f79837;
}
.kurses-section__pagination a:hover svg path {
  fill: #f79837;
}
.kurses-section__pagination a svg path {
  transition: all 0.2s ease;
}