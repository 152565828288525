.kurses-section {
  padding: 80px 0;

  @include respond-below(sm) {
    padding: 40px 0;
  }

  &__featured {
    background-color: $lightBgColor;
    margin-bottom: 50px;
    border-radius: 20px;
    overflow: hidden;

    &__left {
      height: 100%;
      position: relative;
      background-color: #732f6f;

      &__badge {
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 10px;
        background-color: $primaryColor;
        color: #fff;
        padding: 12px 40px;
        font-family: 'Poppins';
        font-weight: 600;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &__right {
      padding: 50px 40px 50px 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond-below(md) {
        padding: 30px 30px 30px 10px;
      }

      @include respond-below(sm) {
        padding: 30px;
      }

      &__category {
        font-size: 14px;
        color: $primaryColor;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 20px;
      }

      &__title {
        color: $headingColor;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
      }

      &__price {
        font-size: 30px;
        color: $headingColor;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;

        @include respond-below(sm) {
          font-size: 1.5em;
        }
      }

      > p {
        margin: 30px 0;

        @include respond-below(sm) {
          margin: 10px 0 20px;
        }
      }

      .row {
        align-items: center;
      }

      .btn-custom {
        text-align: center;
        border-radius: 10px;
        width: 100%;
        padding-top: 19px;
        padding-bottom: 19px;
        font-size: 18px;
        font-weight: 600;

        @include respond-below(sm) {
          padding-inline: 0.5em;
          padding-block: 0.5em;
          font-size: 1em;
        }
      }
    }
  }

  &__grid {
    &__box {
      background-color: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 30px;

      &__course-thumbnail-wrapper {
        position: relative;
        height: 0;
        padding-top: 57%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .heart-button {
          position: absolute;
          top: 10px;
          right: 10px;

          svg {
            path,
            circle {
              transition: all 0.2s ease;
            }
          }

          &:hover {
            svg {
              path {
                fill: $primaryColor;
              }
            }
          }
        }
      }

      &__content {
        padding: 20px 15px;
        background-color: $lightBgColor;

        h3 {
          margin-bottom: 0;
          font-size: 18px !important;
        }

        &__course-category {
          color: #ee9f4c;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .row {
          margin-left: -5px;
          margin-right: -5px;
          align-items: center;

          > div {
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        &__course-price {
          font-family: 'Poppins', sans-serif;
          color: #000;
          font-size: 15px;
          font-weight: 700;
          display: block;
          margin-bottom: 3px;
        }

        &__course-buy {
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 12px;
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    a {
      font-family: 'Poppins', sans-serif;
      color: $headingColor;

      &:hover {
        color: $primaryColor;

        svg path {
          fill: $primaryColor;
        }
      }

      svg path {
        transition: all 0.2s ease;
      }
    }
  }
}
